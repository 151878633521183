body {
  background-color: #ff8d00;
  overscroll-behavior: contain;
}
h1, body, button, h2{
  text-align: center;
  font-family: 'Fredoka One', serif;
}
.buttoncircle{
  text-align: center;
  font-size: 2vh;
}
h1,h2 {
  color: #fff;

}

a{
  color: inherit; /* set the default color of the link */
  text-decoration: none; /* remove the underline from the link */
  margin-bottom: 1vh;
}

.corner-button {
  position: absolute;
  top: 1vh;
  left: 1vh;
  font-size: 2rem;
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

button {
background-color: #fff;
color: #000;
border: 0.6vh solid black;
padding: 1.5vh 2.5vh;
cursor: pointer;
border-radius: 2.5vh;
box-shadow: 0 1vh black;
/*transition: background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out, transform 0.1s ease-in-out;*/
margin-bottom: 1vh;

}

button:hover {
background-color: #fff;
box-shadow: 0 0.7vh black;
transform: translateY(0.3vh);
-webkit-transform: translateY(0.3vh);
}

button:active {
background-color: #fff;
box-shadow: 0 3px black;
transform: translateY(0.7vh);
  -webkit-transform: translateY(0.7vh);
}

button + button {
  margin-top: 0.5vh;
}

.circle {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1vh;
}

.circle img {
border: 0.6vh solid black;
max-width: 100%;
max-height: 100%;
border-radius: 50%;
background-color: #fff;
margin: 1vh;
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
box-shadow: 0 1vh black;

/*transition: background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out, transform 0.1s ease-in-out;*/
}

.circle img:hover {
background-color: #fff;
box-shadow: 0 0.7vh black;
transform: translateY(0.3vh);
  -webkit-transform: translateY(0.3vh);
}

.circle img:active {
background-color: #fff;
box-shadow: 0 0.3vh black;
transform: translateY(0.7vh);
  -webkit-transform: translateY(0.7vh);
}

button, .circle img {
  box-shadow: 0 1vh black;
}

.buttoncircle {
  border: 0.6vh solid black;
width: 5vh;
height: 5vh;
border-radius: 50%;
background-color: #fff;

display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
box-shadow: 0 1vh black;
/*transition: background-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out, transform 0.1s ease-in-out;*/
}

.buttoncircle:hover {
background-color: #fff;
box-shadow: 0 0.7vh black;
transform: translateY(0.3vh);
  -webkit-transform: translateY(0.3vh);
}

.buttoncircle:active {
background-color: #fff;
box-shadow: 0 0.3vh black;
transform: translateY(0.7vh);
  -webkit-transform: translateY(0.7vh);
}

.row {
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
margin-bottom: 1vh;
}

@media only screen and (max-width: 768px) {
  button {
      font-size: 1.6vh;
      width: 31vh;
  }

  .circle{
      width: 17vh;
      height: 17vh;
  }

  h1{
    font-size: 3vh;
    text-shadow: 0 0.3vh black;
  }
  h2{
    font-size: 2vh;
    text-shadow: 0 0.3vh black;
  }
  .buttoncircle{
    margin: 1.1vh;
  }
}
@media only screen and (min-width: 768px) {
  button {
      font-size: 2vh;
      width: 35vh;
  }
  .circle{
      width: 30vh;
      height: 30vh;
  }
  h1{
    font-size: 4vh;
    text-shadow: 0 0.5vh black;
  }
  h2{
    font-size: 2vh;
    text-shadow: 0 0.3vh black;
  }
  .buttoncircle{
    margin: 1.7vh;
  }
}